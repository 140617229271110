// import WL from "../assets/WL1430.png";
// import deuxTubes from "../assets/Deux_tubes.png";
// import troisTubes from "../assets/Trois_tubes.png";
// import pdf from "../assets/pdf/AirSolid_Installation_Documentation.pdf";
import "./popup.css";

export const buildingStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "building-popup";
  let etat = feature.graphic.attributes.ETAT;
  let hauteurEau = feature.graphic.attributes.H_eau_ter;
  let nbPerson = feature.graphic.attributes.Nb_person;
  let courriel = feature.graphic.attributes.courriel;
  let telephone = feature.graphic.attributes.telephone;
  let volume = feature.graphic.attributes.Volume;
  let nbSacs = feature.graphic.attributes.Nb_Sacs;
  // let valBat = feature.graphic.attributes.Valeur_bat;
  // let dmgPc = feature.graphic.attributes.Dmg_pc;
  // let dmgDollar = feature.graphic.attributes.Dmg_dollar;
  // let dmgMoyPc = feature.graphic.attributes.Dmg_moy_pc;
  // let dmgMoyDollar = feature.graphic.attributes.Dmg_moy;
  // let recExacte = feature.graphic.attributes.rec_exacte;
  // let nivRisque = feature.graphic.attributes.niv_risque;
  // let buildingTable =
  //   "<table>\n" +
  //     "    <thead>\n" +
  //     "        <tr>\n" +
  //     "            <th colspan='3'>Information sur le bâtiment</th>\n" +
  //     "        </tr>\n" +
  //     "    </thead>\n" +
  //     "    <tbody>\n" +
  //     "        <tr>\n" +
  //     "            <td>Valeur</td>\n" +
  //     "            <td>" + valBat +"$</td>\n" +
  //     "        </tr>\n" +
  //     "        <tr>\n" +
  //     "            <td>Dommages pour ce scénario</td>\n" +
  //     "            <td>" + dmgDollar.toFixed(0) +"$</td>\n" +
  //     "            <td>" + (dmgPc*100).toFixed(2) +"% de la valeur</td>\n" +
  //     "        </tr>\n" +
  //     "        <tr>\n" +
  //     "            <td>Dommages annuels moyens</td>\n" +
  //     "            <td>" + dmgMoyDollar.toFixed(0) +"$</td>\n" +
  //     "            <td>" + (dmgMoyPc*100).toFixed(2) +"% de la valeur</td>\n" +
  //     "        </tr>\n" +
  //     "        <tr>\n" +
  //     "            <td>Récurrence exacte</td>\n" +
  //     "            <td>" + recExacte +" ans</td>\n" +
  //     "            <td>Niveau de risque " + nivRisque +"</td>\n" +
  //     "        </tr>\n" +
  //     "    </tbody>\n" +
  //     "</table>";
  let tooltipTxt = "";
  // Batiments rouges
   if (etat === "Sous-sol et premier plancher inondé") {
    tooltipTxt = "<li>Basement and first floor <span style='color:red'><b>are flooded</b></span></li>" +
      "<li>Maximum water depth on the ground : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>People to evacuate : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
      "<li>Average annual damage : <span style='color:red'><b>" + (Math.random() * 10000).toFixed(0) + "</b></span> $</li>" +
      "<li>Sandbags : <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
      "<li>Volume of sand : <span style='color:red'><b>" + volume.toFixed(2) + "</b></span> m³</li>" +
      "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
      "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone
  }
  // Batiments oranges
  if (etat === "Sous-sol inondé" || etat === "Eau atteint le bâtiment") {
    tooltipTxt = "<li>Basement <span style='color:red'><b>is flooded</b></span> </li>" +
      "<li>Maximum water depth on the ground : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>People to evacuate : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
      "<li>Average annual damage : <span style='color:red'><b>" + (Math.random() * 10000).toFixed(0) + "</b></span> $</li>" +
      "<li>Sandbags : <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
      "<li>Volume of sand : <span style='color:red'><b>" + volume.toFixed(2) + "</b></span> m³</li>" +
      "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
      "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone
  }
  // Batiments rose
  if (etat === "Isolé (route inondée)") {
    tooltipTxt = "<li>Building <span style='color:red'><b>is isolated (flooded road)</b></span> </li>" +
      "<li>People to evacuate : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
      "<li>Average annual damage : <span style='color:red'><b>" + (Math.random() * 10000).toFixed(0) + "</b></span> $</li>" +
      "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
      "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone
  }
  // // Batiments vert
  // if (etat === "Normal") {
  //     tooltipTxt = "<li>Le bâtiment <span style='color:green'><b>n'est pas inondé</b></span> </li>"
  // }
  div.innerHTML = tooltipTxt;
  return (
    div
  )
};

export const buildingFreestyleStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "building-popup";
  let nbPerson = feature.graphic.attributes.Nb_person;
  let courriel = feature.graphic.attributes.courriel;
  let telephone = feature.graphic.attributes.telephone;
  div.innerHTML = "<li>Number of people : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
    "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
    "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone;
  return (
    div
  )
};

// export const streetStatus = (feature) => {
//   let div = document.createElement("div");
//   div.className = "enun-popup";
//   div.id = "street-popup";
//   let status = feature.graphic.attributes.STATUS;
//   let hauteurEau = feature.graphic.attributes.MAX_PROF;
//   let longSegment = feature.graphic.attributes.Shape_Leng;
//   let tooltipTxt = "";
//   // Routes verte
//   if (status === "Non inondé") {
//     tooltipTxt = "<li>Ce tronçon de route <span style='color:green'><b>n'est pas inondé</b></span> (Praticable)</li>" +
//       "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
//   }
//   // Routes jaune
//   if (status === "Inondé - Praticable") {
//     tooltipTxt = "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Praticable)</li>" +
//       "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
//       "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
//   }
//   // Routes orange
//   if (status === "Inondé - Véhicules d'urgence seulement") {
//     tooltipTxt = "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Véhicule d'urgence seulement)</li>" +
//       "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
//       "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
//   }
//   // Routes rouge
//   if (status === "Inondé - Impraticable") {
//     tooltipTxt = "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Impraticable)</li>" +
//       "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
//       "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
//   }
//   div.innerHTML = tooltipTxt;
//   return (
//     div
//   )
// };
export const streetStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "street-popup";
  let status = feature.graphic.attributes.STATUS;
  let hauteurEau = feature.graphic.attributes.MAX_PROF;
  let longSegment = feature.graphic.attributes.Shape_Leng;
  let tooltipTxt = "";
  // Routes verte
  if (status === "Non inonde") {
    tooltipTxt = "<li>This section of road <span style='color:green'><b>is not flooded</b></span> (Praticable)</li>" +
      "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  // Routes jaune
  if (status === "Inonde - Praticable") {
    tooltipTxt = "<li>This section of road <span style='color:red'><b>is flooded</b></span> (Passable)</li>" +
      "<li>Maximum water depth of the section : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>Section length : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  // Routes orange
  if (status === "Inonde - Vehicules d'urgence seulement") {
    tooltipTxt = "<li>This section of road <span style='color:red'><b>is flooded</b></span> (Emergency vehicle only)</li>" +
      "<li>Maximum water depth of the section : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>Section length : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  // Routes rouge
  if (status === "Inonde - Impraticable") {
    tooltipTxt = "<li>This section of road <span style='color:red'><b>is flooded</b></span> (Impassable)</li>" +
      "<li>Maximum water depth of the section : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>Section length : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  div.innerHTML = tooltipTxt;
  return (
    div
  )
};

// Pour Sacs de sable
export const bagStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "bag-popup";
  let nbSacs = feature.graphic.attributes.Nb_Sacs;
  let infoSacs, hauteurMax;

  if (nbSacs === undefined) {
    infoSacs = "<p>Information non disponible</p>";
  } else {
    let volume = feature.graphic.attributes.Volume.toFixed(2);
    infoSacs = "<li>Number of sandbags: <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
      "<li>Sand volume: <span style='color:red'><b>" + volume + "</b></span> m³</li><br>";
  }
  let longueur = feature.graphic.attributes.longueur.toFixed(0);
  let hauteur = feature.graphic.attributes.hauteur.toFixed(2);

  if (hauteur <= 0.8) {
    hauteurMax = 0.8;
  } else if (hauteur <= 1.25) {
    hauteurMax = 1.25;
  } else if (hauteur <= 1.4) {
    hauteurMax = 1.4;
  } else {
    hauteurMax = 1.4;
  }
  let tooltipTxt =
    "<li>Maximum water height forecast: <span style='color:red'><b>" + hauteur + "</b></span> m</li>" +
    "<li>Maximum holding height: <span style='color:red'><b>" + hauteurMax + "</b></span> m</li>" +
    "<li>Length of the dike: <span style='color:red'><b>" + longueur + "</b></span> m</li>" +
    "<p class='enun-popup-title'>Sandbags</p>" + infoSacs
  div.innerHTML = tooltipTxt;
  return (
    div
  );
};

// // Pour Sacs de sable DIGUE EXPRESS
// export const bagStatus = (feature) => {
//   let div = document.createElement("div");
//   div.className = "enun-popup";
//   div.id = "bag-popup";
//   let nbSacs = feature.graphic.attributes.Nb_Sacs;
//   let tooltipTxt = "";
//   let infoSacs, hauteurMax, modele, image, temps, nbOper, budget;
//
//   if (nbSacs === undefined) {
//     infoSacs = "<p>Information non disponible</p>";
//   } else {
//     let volume = feature.graphic.attributes.Volume.toFixed(2);
//     infoSacs = "<li>Nombre de sacs de sable: <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
//       "<li>Volume de sable: <span style='color:red'><b>" + volume + "</b></span> m³</li><br>";
//   }
//   let longueur = feature.graphic.attributes.longueur.toFixed(0);
//   let hauteur = feature.graphic.attributes.hauteur.toFixed(2);
//
//   if (hauteur <= 0.8) {
//     hauteurMax = 0.8;
//     modele = "2 bi-tubes Ø1000";
//     image = deuxTubes;
//     temps = 3.5;
//     nbOper = 3;
//     budget = 5000;
//   } else if (hauteur <= 1.25) {
//     hauteurMax = 1.25;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø600";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 7000;
//   } else if (hauteur <= 1.4) {
//     hauteurMax = 1.4;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø800";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 9000;
//   } else {
//     hauteurMax = 1.4;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø800";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 9000;
//   }
//   tooltipTxt =
//     infoSacs +
//     "<p class='enun-popup-title'>Digue Express®</p>" +
//     "<div class='model-container'><div class='model-text'>" + modele + "</div><img id='model-image' src=" + image + "></div>" +
//     "<li>Hauteur d'eau maximale prévue: <span style='color:red'><b>" + hauteur + "</b></span> m</li>" +
//     "<li>Hauteur de retenue maximale: <span style='color:red'><b>" + hauteurMax + "</b></span> m</li>" +
//     "<li>Longueur de la digue: <span style='color:red'><b>" + longueur + "</b></span> m</li>" +
//     "<li>Temps de mise en place: <span style='color:red'><b>" + temps + "</b></span> heures</li>" +
//     "<li>Nombre d'opérateurs: <span style='color:red'><b>" + nbOper + "</b></span></li>" +
//     "<li>Budget: <span style='color:red'><b>" + budget + "</b></span> $</li>" +
//     "<a href=" + pdf + " download='AirSolid_Installation_Documentation'>Télécharger les instructions et la documentation</a>";
//   div.innerHTML = tooltipTxt;
//   return (
//     div
//   );
// };