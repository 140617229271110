// Assomption
export const AS = {
  configName: 'assomption',
  scenarioDepart: 16,
  nbScenario: 81,
  startScenario: 1,
  stepScenario: 1,
  labels: ["100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295", "300", "305", "310", "315", "320", "325", "330", "335", "340", "345", "350", "355", "360", "365", "370", "375", "380", "385", "390", "395", "400", "405", "410", "415", "420", "425", "430", "435", "440", "445", "450", "455", "460", "465", "470", "475", "480", "485", "490", "495", "500"],
  dataIsole: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 6, 6, 6, 6, 6, 6, 7, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 13, 16, 15, 16, 16, 30, 28, 28, 34, 39, 34, 34, 79, 88, 105, 103, 105, 106, 101, 99, 103, 112, 107, 106, 103, 85, 87, 82, 84, 72, 73, 70, 63, 61, 55, 49, 44],
  dataSS: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 4, 4, 4, 4, 5, 7, 11, 12, 12, 11, 15, 16, 18, 20, 23, 25, 28, 40, 49, 59, 67, 80, 90, 99, 104, 113, 121, 122, 128, 138, 200, 214, 230, 244, 255, 266, 281, 302, 304, 308, 306, 306, 307, 300, 281, 265, 254, 240, 222],
  dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 4, 4, 4, 5, 5, 5, 6, 6, 7, 7, 7, 8, 10, 13, 14, 16, 20, 24, 25, 29, 40, 43, 47, 57, 68, 70, 76, 90, 103, 116, 132, 144, 164, 195, 223, 249, 269, 294],
  dataTot: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 4, 4, 4, 4, 5, 7, 11, 12, 13, 13, 17, 20, 22, 24, 28, 30, 33, 46, 55, 66, 74, 87, 98, 109, 117, 127, 137, 142, 152, 163, 229, 254, 273, 291, 312, 334, 351, 378, 394, 411, 422, 438, 451, 464, 476, 488, 503, 509, 516],
  dataEvac: [13, 13, 13, 13, 13, 13, 16, 16, 16, 16, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 14, 14, 23, 24, 24, 24, 19, 19, 25, 20, 20, 20, 21, 28, 45, 48, 53, 55, 72, 85, 109, 123, 135, 142, 155, 243, 263, 297, 338, 392, 408, 446, 596, 652, 720, 736, 775, 814, 1008, 1074, 1142, 1226, 1275, 1322, 1369, 1399, 1452, 1484, 1526, 1539, 1583, 1614, 1636, 1667, 1690, 1691, 1698],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_BatimentsPoints/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_SacsSable/FeatureServer/",
  // bagPtsUrl: "https://services5.arcgis.com/mCb6CplkZg4pCYYJ/arcgis/rest/services/SacsSable_Assomption_Points/FeatureServer/",
  incertMin_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Incert_Min/FeatureServer/",
  incertMax_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Incert_Max/FeatureServer/",
  centerView: [-73.444359, 46.041463],
  centerViewZoom: 15,
  // typeMesureGraph: "débit",
  // typeMesure: "Débit",
  // uniteMesure: " m³/s",
  typeMesureDebit: true,
  btn2019: 35, //340 m3s
  btnHier: 11,
  btnTempsReel: 16,
  btnUnJour: 23,
  btnTroisJours: 38,
  yearMarkers: [
    {
      value: 16, // This is the value position in the labels props, equals to 180
      label: '2',
    },
    {
      value: 46,
      label: '20',
    },
    {
      value: 59,
      label: '100',
    }
  ],
  isVisible: false
};

// Deux Montagnes
export const DM = {
  configName: 'deuxMontagnes',
  scenarioDepart: 12,
  nbScenario: 68,
  startScenario: 0,
  stepScenario: 1,
  // DeuxMontagnes complet
  // labels: ["22.70", "22.75", "22.80", "22.85", "22.90", "22.95", "23.00", "23.05", "23.10", "23.15", "23.20", "23.25", "23.30", "23.35", "23.40", "23.45", "23.50", "23.55", "23.60", "23.65", "23.70", "23.75", "23.80", "23.85", "23.90", "23.95", "24.00", "24.05", "24.10", "24.15", "24.20", "24.25", "24.30", "24.35", "24.40", "24.45", "24.50", "24.55", "24.60", "24.65", "24.70", "24.75", "24.80", "24.85", "24.90", "24.95", "25.00", "25.05", "25.10", "25.15", "25.20", "25.25", "25.30", "25.35", "25.40", "25.45", "25.50", "25.55", "25.60", "25.65", "25.70", "25.75", "25.80", "25.85", "25.90", "25.95", "26.00", "24.70", "24.70"],
  // dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 4, 4, 3, 4, 4, 13, 8, 21, 19, 36, 43, 50, 54, 56, 67, 68, 80, 108, 102, 121, 127, 171, 160, 153, 181, 270, 254, 271, 328, 348, 375, 382, 383, 568, 677, 678, 699, 691, 721, 766, 771, 801, 911, 1106, 1036, 1070, 1029, 1215, 1304, 1299, 1405, 181, 175],
  // dataSS: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 3, 5, 5, 6, 6, 7, 13, 12, 15, 19, 28, 31, 36, 42, 62, 69, 88, 103, 114, 142, 174, 292, 322, 348, 382, 411, 457, 483, 510, 513, 553, 539, 590, 608, 678, 697, 781, 787, 867, 874, 957, 987, 1061, 1097, 1218, 1231, 1341, 1290, 1386, 1359, 1457, 1430, 1544, 1512, 1662, 1656, 513, 483],
  // dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 2, 4, 4, 5, 5, 7, 7, 9, 10, 15, 16, 24, 26, 34, 36, 51, 55, 85, 103, 154, 173, 233, 237, 282, 288, 350, 352, 419, 423, 525, 529, 616, 622, 727, 736, 863, 872, 1004, 1014, 1173, 1183, 1344, 1353, 1539, 1546, 1725, 154, 154],
  // dataTot: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 3, 5, 5, 6, 6, 7, 13, 13, 16, 21, 30, 35, 40, 47, 67, 76, 95, 112, 124, 157, 190, 316, 348, 382, 418, 462, 512, 568, 613, 667, 726, 772, 827, 890, 966, 1047, 1133, 1206, 1290, 1399, 1486, 1603, 1683, 1824, 1954, 2094, 2213, 2294, 2400, 2532, 2640, 2774, 2897, 3051, 3208, 3381, 667, 637],
  // dataEvac: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 8, 16, 16, 19, 22, 33, 58, 52, 69, 89, 150, 146, 203, 219, 327, 377, 456, 516, 559, 672, 780, 1229, 1413, 1483, 1650, 1805, 2077, 2210, 2326, 2580, 3055, 3138, 3348, 3719, 4017, 4351, 4651, 4890, 5683, 6372, 6635, 7050, 7241, 7747, 8136, 8601, 9021, 9576, 10473, 10663, 11066, 11337, 12287, 12998, 13484, 14302, 2580, 2440],
  // Secteur Vaudreuil
  labels: ["22.70", "22.75", "22.80", "22.85", "22.90", "22.95", "23.00", "23.05", "23.10", "23.15", "23.20", "23.25", "23.30", "23.35", "23.40", "23.45", "23.50", "23.55", "23.60", "23.65", "23.70", "23.75", "23.80", "23.85", "23.90", "23.95", "24.00", "24.05", "24.10", "24.15", "24.20", "24.25", "24.30", "24.35", "24.40", "24.45", "24.50", "24.55", "24.60", "24.65", "24.70", "24.70 digue", "24.75", "24.80", "24.85", "24.90", "24.95", "25.00", "25.05", "25.10", "25.15", "25.20", "25.25", "25.30", "25.35", "25.40", "25.45", "25.50", "25.55", "25.60", "25.65", "25.70", "25.75", "25.80", "25.85", "25.90", "25.95", "26.00"],
  dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 4, 4, 7, 4, 10, 6, 10, 8, 10, 7, 8, 8, 10, 11, 34, 36, 39, 48, 42, 36, 33, 37, 37, 54, 69, 95, 97, 112, 130, 117, 127, 125, 240, 250, 233, 242, 232, 281, 268, 292, 290, 321, 276, 331, 301, 449, 562, 573, 544],
  dataSS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 5, 6, 8, 8, 11, 15, 18, 19, 24, 32, 47, 55, 62, 70, 80, 91, 112, 123, 136, 153, 153, 171, 180, 203, 222, 265, 292, 339, 352, 396, 422, 476, 520, 565, 603, 692, 733, 811, 781, 839, 823, 881, 859, 939, 917, 1030, 1034],
  dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 2, 5, 5, 11, 12, 19, 19, 21, 29, 29, 40, 42, 61, 62, 86, 88, 130, 132, 165, 167, 222, 226, 287, 290, 376, 381, 481, 488, 603, 608, 736, 740, 862],
  dataTot: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 5, 6, 8, 8, 11, 15, 18, 20, 25, 33, 48, 56, 63, 71, 82, 96, 117, 134, 148, 172, 172, 192, 209, 232, 262, 307, 353, 401, 438, 484, 552, 608, 685, 732, 825, 918, 1020, 1101, 1157, 1220, 1304, 1369, 1462, 1547, 1653, 1770, 1896],
  dataEvac: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 4, 3, 16, 20, 25, 25, 41, 31, 56, 44, 67, 70, 87, 86, 107, 129, 189, 217, 299, 331, 378, 450, 495, 525, 560, 642, 642, 768, 868, 1013, 1103, 1288, 1481, 1595, 1747, 1870, 2450, 2653, 2831, 2979, 3208, 3596, 3883, 4183, 4340, 4640, 4762, 5099, 5294, 6007, 6644, 7048, 7338],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_Plaine_Inondab",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_Batiments_Points/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_SacsSable/FeatureServer/",
  // bagPtsUrl: "https://services5.arcgis.com/mCb6CplkZg4pCYYJ/arcgis/rest/services/SacsSable_Digue_Vaudreuil_Points/FeatureServer/",
  incertMin_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/IncertitudesMin_Vaudreuil/FeatureServer/",
  incertMax_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/IncertitudesMax_Vaudreuil/FeatureServer/",
  // centerView: [-73.995097, 45.450559], // DeuxMontagnes complet
  // centerViewZoom: 13, // DeuxMontagnes complet
  centerView: [-73.990657, 45.391444], // Secteur Vaudreuil
  // centerView: [-73.990909, 45.390755], // Pour test
  centerViewZoom: 15, // Secteur Vaudreuil
  // centerViewZoom: 17, // Pour test
  // typeMesureGraph: "niveau",
  // typeMesure: "Niveau d'eau",
  // uniteMesure: " m",
  typeMesureDebit: false,
  btn2019: 40, //340 m3s
  btnHier: 1,
  btnTempsReel: 2,
  btnUnJour: 18,
  btnTroisJours: 34,
  yearMarkers: [
    {
      value: 13, // This is the value position in the labels props, equals to 23.35
      label: '2',
    },
    {
      value: 28,
      label: '20',
    },
    {
      value: 35,
      label: '100',
    },
  ],
  isVisible: false
};

// Mille Iles
export const MI = {
  configName: 'milleIles',
  scenarioDepart: 8,
  nbScenario: 37,
  startScenario: 1,
  stepScenario: 1,
  labels: ["700", "725", "750", "775", "800", "825", "850", "875", "900", "925", "950", "975", "1000", "1025", "1050", "1075", "1100", "1125", "1150", "1175", "1200", "1225", "1250", "1275", "1300", "1325", "1350", "1375", "1400", "1425", "1450", "1475", "1500", "1525", "1550", "1575", "1600"],
  dataIsole: [4, 4, 4, 4, 7, 19, 21, 33, 37, 39, 36, 31, 32, 41, 39, 36, 36, 38, 44, 31, 22, 33, 32, 32, 43, 39, 41, 45, 39, 38, 34, 28, 33, 23, 22, 35, 52],
  dataSS: [0, 0, 0, 0, 3, 7, 10, 15, 17, 23, 28, 34, 40, 46, 54, 64, 83, 99, 114, 133, 146, 163, 180, 200, 223, 227, 243, 254, 260, 257, 249, 276, 276, 281, 287, 296, 302],
  dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 6, 10, 14, 15, 16, 27, 33, 40, 47, 54, 71, 86, 95, 116, 138, 164, 186, 213, 238, 261, 288, 314],
  dataTot: [0, 0, 0, 0, 3, 7, 10, 15, 17, 23, 28, 34, 41, 49, 58, 70, 93, 113, 129, 149, 173, 196, 220, 247, 277, 298, 329, 349, 376, 395, 413, 462, 489, 519, 548, 584, 616],
  dataEvac: [15, 15, 15, 15, 29, 78, 92, 140, 166, 192, 201, 203, 236, 282, 307, 325, 399, 458, 529, 536, 579, 676, 746, 824, 947, 1000, 1098, 1157, 1227, 1286, 1312, 1447, 1545, 1610, 1694, 1840, 1997],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_secteur_Plaine_Ino",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Batiments_gc_secteur_freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_sacs_sable/FeatureServer/",
  incertMin_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Incertitudes_min/FeatureServer/",
  incertMax_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Incertitudes_max/FeatureServer/",
  photoUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/mosaic_photo_validation/MapServer",
  zi2Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_2/FeatureServer",
  zi20Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_20/FeatureServer",
  zi100Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_100/FeatureServer",
  ziLavalUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ZI_laval_1190m3s/FeatureServer",
  centerView: [-73.863250, 45.559485],
  centerViewZoom: 15,
  typeMesureDebit: true,
  btn2019: 16, //1090 m3s
  btnHier: 5,
  btnTempsReel: 14,
  btnUnJour: 20,
  btnTroisJours: 27,
  yearMarkers: [
    {
      value: 4, // This is the value position in the labels props, equals to 180
      label: '2', //810
    },
    {
      value: 21,
      label: '20', //1222
    },
    {
      value: 28, //1408
      label: '100',
    }
  ],
  isVisible: false
};