import React from "react";
import Header from "./Header";
import BottomPanel from "./BottomPanel";
import {WaterLevelMap} from "./WaterLevelMap";
import {DM} from "../constants/appsConstants";
import * as actionCreators from "../store/actions";
import {connect} from "react-redux";
import {Mentions} from "./Mentions";
import {Legende} from "./Legende";
// import {Measure} from "./Measure";


class App2 extends React.Component {
  // state = {exportDataSet: []};
  // callbackFunction = (childData) => this.setState({exportDataSet: childData});


  render() {
    this.props.onTypeMesureChange();  //For "Niveau d'eau" instead of "debit"
    return (
      <div className="app">
        <Header
          scenarioDepart={DM.scenarioDepart}
          nbScenario={DM.nbScenario}
          labels={DM.labels}
          btn2019={DM.btn2019}
          btnHier={DM.btnHier}
          btnTempsReel={DM.btnTempsReel}
          btnUnJour={DM.btnUnJour}
          btnTroisJours={DM.btnTroisJours}
          yearMarkers={DM.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={DM.scenarioDepart}
          labels={DM.labels}
          dataIsole={DM.dataIsole}
          dataSS={DM.dataSS}
          dataPrem={DM.dataPrem}
          dataTot={DM.dataTot}
          dataEvac={DM.dataEvac}
          // exportDataSet={this.state.exportDataSet}
        />

        <WaterLevelMap
          configuration={DM}
          // exportDataSetCallback={this.callbackFunction}
          labels={DM.labels}
          store={this.props.store}
        />

        <Mentions/>

        <Legende/>
        {/*<Measure/>*/}

      </div>
    );
  }
}

// const mapStateToProps = state => {
//     return {
//         currentScenario: state.scenarioId,
//         // activateBatiments: state.batiments
//     };
// };
//
const mapDispatchToProps = (dispatch) => {
  return {
    onTypeMesureChange: () => dispatch(actionCreators.changeTypeMesure()),
  };
};


export default connect(null, mapDispatchToProps)(App2);
// export default App2;
